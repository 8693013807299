import React from "react";
import NavBar from "../components/Navbar";
import AppDevBannerContainer from "../components/AppDevBannerContainer";
import AppDevBenefitContainer from "../components/AppDevBenefitContainer";
import ContactUs from "./ContactUs";

const AppDevDetails = () => {
  return (
    <>
      <NavBar />
      <section className="detail-container">
        <AppDevBannerContainer />
      </section>
      <section className="benefit-container">
        <AppDevBenefitContainer />
      </section>
      <ContactUs />
    </>
  );
};

export default AppDevDetails;
