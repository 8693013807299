import styled from 'styled-components';

const Wrapper = styled.div`
    box-shadow: 7px 4px 20px rgb(0 0 0 / 7%);
    position: fixed;
    z-index: 99;
    background: white;
    width: 100%;
    height: 100px;
    padding-top: 8px;

    a {
      text-decoration: none;
    }
    .navbar-top {
        display: flex;
        align-items: inherit;
        padding: 0px 20px;
        margin: 8px 90px;
    }
    .navbar-top-logo {
      width: 140px;
      /* filter: sepia(114%) hue-rotate(190deg) saturate(9000%); */
    }
    .navbar-top-logo a {
      display: flex;
    }
    .titleImg {
        width: 100%;
        margin-right: 15px;
    }
    .navbar-top-desktop {
      flex: 1 1 auto;
    }
    .navbar-links{
        display: flex;
        justify-content: flex-end;
        padding: 0;
        align-items: center;
    }
    .navbar-top-desktop .navbar-links li {
      list-style-type: none;
      margin-left: 40px;
      padding: 5px 0;
    }
    .navbar-top-desktop .navbar-links li > a {
        text-decoration: none;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 1px;
    }
    .navbar-top .navbar-links {
      margin-bottom: 10px;
    }
    .navbar-top .navbar-links a {
      color: rgba(61,61,61,0.65);
    }
    .navbar-bottom {
      margin: 0px 70px;
    }
    .navbar-bottom .navbar-links {
      margin-top: -10px;
    }
    .navbar-top .navbar-links li:hover a {
      color: rgb(0, 102, 255);
      transition: color 0.15s ease-out;
    }
`;

export default Wrapper;