// import icon1 from '../images/digital-product-eng-micon.svg';
// import icon2 from '../images/performance-testing-micon.svg';
// import icon3 from '../images/app-management-modernization-micon.svg';
// import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

import icon1 from '../images/svg/icons/cloud-service.png'
import icon2 from '../images/svg/icons/application-settings.png';
import icon3 from '../images/svg/icons/analysis.png';
import icon4 from '../images/svg/icons/crm.png';
import icon5 from '../images/svg/icons/ux.png';
import icon6 from '../images/svg/icons/qa.png';

export const outsourcing = [
    
    {
        id: 2,
        title: "Application Development",
        icon: icon2,
        to:"/details/appdev",
        description: "Application development services help create well-designed, user-friendly and fast apps for external or internal users. LogicSure offers experience in UX and UI design, different architecture patterns and tech stacks for app development.",
    },
    {
        id: 3,
        title: "Analytics",
        icon: icon3,
        to:"/details/analytics",
        description: "We help you extract meaningful insights from any data source and drive business with informed decision-making capabilities.         ",
    },
    {
        id: 4,
        title: "Salesforce",
        icon: icon4,
        to:"/details/salesforce",
        description: "We understand the true power of the Salesforce platform, and we always strive to address your needs using out-of-the-box features of Salesforce to the maximum extent. We will resort to custom development only in cases where it is absolutely necessary.",
    },
    {
        id: 1,
        title: "Cloud Technologies",
        icon: icon1,
        to:"/details/cloud",
        description: "Our team will collaborate with you to assess your current IT setup to define an ideal approach to migrate your business applications into cloud, and execute it successfully regardless of your application’s complexity. LogicSure is also efficient in delivering scalable, secured and high performing custom cloud applications when the situation calls for it.",
    },
    // {
    //     id: 5,
    //     title: "Web Technologies",
    //     icon: icon5,
    //     to:"/details/web",
    //     description: "Web development services help create all types of web-based software and ensure great experience for web users. Different types of web solutions may seem similar from the outside but we approach them differently and know what factors are winning in each case.",
    // },
    {
        id: 6,
        title: "QA Services",
        icon: icon6,
        to:"/details/quality",
        description: "Our QA services will help you establish concrete control over your product’s life cycle, monitor every development stage, and give you accurate product quality information. For over a decade, our QA experts have been using the latest practices and technologies to ensure efficient performance and high-quality project implementation for the world’s largest enterprises, aiding in delivering solid software on time.",
    }
];

export const perspective = [
    {
        id: 1,
        title: "Building Battle-tested Software Architecture: Lessons from Top Tech Companies",
        icon: bookImg1,
        description: "A one-stop guide that provides comprehensive and expert advice to CTOs & tech decision-makers for evolving software architecture at scale.",
    },
    {
        id: 2,
        title: "CTOs Handbook to Build an Enterprise Kubernetes Strategy",
        icon: bookImg2,
        description: "Adoption of new technologies is advantageous yet challenging. This eBook is for each & tech-savvy enterprise that wants to embrace...",
    },
    {
        id: 3,
        title: "CTO’s Handbook for Building Scalable Frontend",
        icon: bookImg3,
        description: "In a typical startup scenario, you have a great business idea that gets validated in the market through MVP. After that, the number of users starts to grow...",
    },
    {
        id: 4,
        title: "20+ Ways Microservices are Transforming Industries: Use Cases from Capital One, Facebook, Uber, and more",
        icon: bookImg4,
        description: "The business world has realized the significance of adopting a microservices architecture and how it can transform the development...",
    }
];