import styled from 'styled-components';

const Wrapper = styled.div`
    flex: 0 0 500px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px 0px;
    background-color: rgb(255, 255, 255);
    margin: 20px 20px 20px 0px;
    padding: 20px;

    .form-title {
        font-size: 20px;
        color: rgba(61, 61, 61, 0.5);
        text-align: center;
        margin-bottom: 25px;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

    .form-row {
        display: flex;
        flex-direction: column;
        // min-width: 350px;
        position: relative;
    }

    .form-input, .form-textarea {
        font-size: 16px;
        height: 25px;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid rgb(232, 233, 243);
        border-radius: 5px;
        background-color: rgb(249, 252, 254);
    }

    .form-textarea {
        resize: none;
        height: 150px;
        font-family: inherit;
    }

    .form-input::placeholder, .form-textarea::placeholder {
        color: rgba(61, 61, 61, 0.5);
    }

    .form-textarea:focus, .form-input:focus{
        outline: none;
    }

    .form button {
        height: 50px;
        margin: 27px 0px 5px;
    }

    .form-input + label, .form-textarea + label {
        font-size: 16px;
        padding: 0 12px;
        color: #999;
        pointer-events: none;
        position: absolute;
        top: -13px;
        transform: translate(0, 26px) scale(1);
        transform-origin: top left;
        transition: all 0.2s ease-out;
    }

    .form-input:focus + label,
    .form-input:not(:focus):valid + label,
    .form-textarea:focus + label,
    .form-textarea:not(:focus):valid + label 
    {
        top: -20px;
        background: #fff;
        padding: 0px 2px;
        transform: translate(13px, 12px) scale(0.75);
    }

    .form-input:focus, .form-textarea:focus {
        border-color: rgb(41, 222, 213);
    }
`;

export default Wrapper;