import icon1 from '../images/digital-product-eng-micon.svg';
import icon2 from '../images/performance-testing-micon.svg';
import icon3 from '../images/app-management-modernization-micon.svg';
import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

export const web_services = [
    {
        id: 1,
        title: "Product Development",
        subtitle: "Turn ideas into powerful digital products",
        icon: icon1,
        to:"/details/web",
        description: "Get high quality customized software products within the proposed time and budget. Review of technical and functional practicality of the software product. End-to-end software product development using multiple methodologies and practices including Agile, SCRUM & DevOps",
    },
    {
        id: 2,
        title: "Web Design Services",
        subtitle: "Build products that deliver a consistently great experience. Test early and often.",
        icon: icon2,
        to:"/details/web",
        description: "We’re a web application design and development firm that has the potential of digitally transforming your business with feature-packed, high-performing web solutions. With the latest web technologies we help you scale as your enterprise grows with custom, responsive, secure, SEO optimized & professionally styled websites.",
    },
    {
        id: 6,
        title: "Enterprise Application Development",
        subtitle: "Turn ideas into powerful digital products",
        icon: icon2,
        to:"/details/web",
        description: "we help build, deliver and manage enterprise applications that help improve business agility and foster innovation across the board. Clients can deal with multi-device auto-responsive interfaces and componentized app assembly with Rapid App Development (RAD) methodology. We offer container deployment (app-optimized) on private infrastructures with Docker, along with scalable integration using APIs and Microservices Architecture (MSA). We build enterprise web platforms that help simplify and accelerate day-to-day business activities, keeping in line with your business strategies, corporate theme, and transformation roadmap.",
    },
    {
        id: 3,
        title: "Migration & Integration",
        subtitle: "World-class risk mitigated migration and integration solutions",
        icon: icon3,
        to:"/details/web",
        description: "Improve organization efficiency by building automatic process/information flow with other systems and software by seamless integration. Move from a legacy system to a more advanced technology based or platform-based system to enable automation and to scale up with ease.",
    },
    {
        id: 4,
        title: "Quality Analysis & Testing",
        subtitle: "Establish and maintain an efficient QA process throughout the entire software development lifecycle",
        icon: icon1,
        to:"/details/web",
        description: "Quality assurance with a balanced composition of manual and automated testing. Expert quality testing ensuring product is secure and scalable at ease. App monitoring and risk management to improve product life expectancy",
    },
    {
        id: 5,
        title: "Maintenance & Support",
        subtitle: "Evoke multi-tier support and maintenance services that can be adjusted flexibly to business needs",
        icon: icon4,
        to:"/details/web",
        description: "Dedicated support team fixing bugs/errors and maintaining product stability. Process optimization and efficiency maintenance by upgrading/updating to the latest technologies. Technical support to existing product users while migrating to the latest version",
    }
];
