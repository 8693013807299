import styled from 'styled-components';

const Wrapper = styled.div `
    display: flex;

    .service-icon {
        height: 60px;
        margin: 10px 2px;
        padding: 5px;
    }
    .service-details {
        margin: 5px;
        padding: 5px;
        width: 450px;
    }
    .service-title {
        margin-top: 5px;
        font-size: 20px;
        font-weight: 600;
    }
`;

export default Wrapper;