import React from "react";
import IntroContainer from "../components/IntroContainer";
import Navbar from "../components/Navbar";
import OutsourcingCard from "../components/OutsourcingCard";
import { outsourcing } from "../assets/data/serviceDetails";
import BenifitWrapper from "../assets/wrappers/BenefitContainer";
import img1 from "../assets/images/eminent-expertise@2x.webp";
import homeImg1 from '../assets/images/svg/homepage/Team1-work-amico.svg' 
import ContactUs from "./ContactUs";

const Home = () => {
  return (
    <>
      <Navbar />
      <IntroContainer />
      <BenifitWrapper>
        <div className="benefit-cards">
          <div className="three-layer-block">
            <img className="benefit-card-img" src={homeImg1} alt="img-1" />
          </div>
          <div className="benefit-card-detail">
            <h3 className="benefit-card-title">About Us</h3>
            <p>
              We are an IT Consulting and Integrator of independent solutions
              focused on cloud solutions and market leading platforms. We work
              with the world’s leading organizations to provide collaborative
              solutions to their business and IT challenges with innovative
              ideas that are embraced by their customers. We have proven
              expertise in delivering high-end IT services and solutions with
              our in-depth understanding of various domains, with strong
              technology partners, high consulting capabilities and technical
              expertise.
            </p>
          </div>
        </div>
      </BenifitWrapper>
      <div className="outsourcing-container">
        <h1>
          Explore <span>Our Services</span>
        </h1>
        <h3 className="outsourcing-subtitle">
          From IT strategy consulting and comprehensive technology roadmaps to
          the end-to-end development of scalable solutions, Intellectsoft
          delivers a full-cycle software development services that adapt
          seamlessly to your project requirements and business needs.
        </h3>
        {outsourcing.map((detail) => (
          <OutsourcingCard
            key={detail.id}
            icon={detail.icon}
            title={detail.title}
            description={detail.description}
            to={detail.to}
          />
        ))}
      </div>
      <div className="perspective-container"></div>
      <ContactUs />
    </>
  );
};

export default Home;
