import React from 'react'
import Button from './Button'
import homeImg from '../assets/images/home-hero-banner@2x.webp';
// import homeImg from '../assets/images/svg/homepage/office_02.jpg' 
import Wrapper from '../assets/wrappers/IntroContainer';

const IntroContainer = () => {
  return (
    <Wrapper>
        <div className="intro-data-container">
            <h1 className="title">Digital  <br></br><span>Transformation</span></h1>
            <h3 className="subtitle">Software Consulting and Development for your Digital Success.</h3>
            {/* <Button type='button' text="Scale your dream team" bgclr={'rgb(0, 102, 255)'} fontSize="18px" fontWeight="400"/> */}
        </div>
        <div className="intro-img-container">
          <img src={homeImg} alt="homeImg" className="home-img"/>
        </div>
    </Wrapper>
  )
}

export default IntroContainer