import icon1 from '../images/digital-product-eng-micon.svg';
import icon2 from '../images/performance-testing-micon.svg';
import icon3 from '../images/app-management-modernization-micon.svg';
import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

export const cloud_services = [
    {
        id: 1,
        title: "Cloud Migration Services",
        subtitle: "We deliver proven tools, methodologies, and expertise that ensure an accelerated, hassle-free migration to Cloud",
        icon: icon1,
        to:"/details/cloud",
        description: "Enterprises are adopting cloud to their business in a rapid pace to keep up with the constantly evolving business needs and the ever changing customer demands. You need the help of an experienced cloud partner who can take your business to cloud without causing any hindrance to your business operations. Our Team can seamlessly migrate your business operation to cloud for optimal performance. We can help you with complete infrastructure migration, on-premise business application migration, re-architect existing applications for cloud deployments, data migration or platform migration.",
    },
    {
        id: 2,
        title: "Cloud Application Development Services",
        subtitle: "We offer full-cycle development services to create applications from scratch by leveraging SaaS & PaaS models as well as modernizing them.",
        icon: icon2,
        to:"/details/cloud",
        description: "Whether you are looking to build a completely new application on the cloud or looking to move your existing On-premise applications to the cloud, our cloud experts can help you do it right. Our cloud architects and developers are adept at developing applications on Azure, AWS and Force.com platforms, enabling your applications to leverage the power and scalability of cloud, while reducing your overall IT infrastructure costs. We have also helped many enterprises re-architect their complex On-premise applications to be deployed on cloud platforms while also ensuring the improvement in application performance.",
    },
    {
        id: 3,
        title: "Cloud Managed Services",
        subtitle: "We ensure that every cloud resource you own is being used securely and effectively, and that you can leverage managed cloud computing services",
        icon: icon3,
        to:"/details/cloud",
        description: "Managing the cloud infrastructure all by yourself is a time consuming activity. Leave your cloud management to our team of cloud experts while you can focus on your core business. We offer 24/7 cloud management services which covers complete cloud infrastructure management, cloud monitoring, cloud usage optimization, DevOps, cloud automation and much more. Our cloud consultants can analyze your existing cloud infrastructure setup to identify gaps and offer solutions to address them for improvements. We continuously monitor to identify threats and manage incidents 24/7.",
    },
    {
        id: 4,
        title: "DevOps & Automation",
        subtitle: " Deliver value with agility by shortening your release cycles with CI/CD.",
        icon: icon1,
        to:"/details/cloud",
        description: "Though the cloud and DevOps are totally two different concepts, Our Team clearly understands how these both can be applied together for any business in order to achieve the highest operational efficiency. Our Team can transform your cloud application development process and speed-up product release time to market. DevOps services in the cloud with the experienced partner results in reduced amount of application delivery time and lowered cost of application development, deployment, operations and support.",
    },
    {
        id: 5,
        title: "Big Data, Machine Learning And IoT",
        subtitle: "Big Data, IoT and the Cloud are digital solutions that enable better analytics and decision-making for your business.",
        icon: icon4,
        to:"/details/cloud",
        description: "In today’s fast paced market, organizations must undergo digital transformation in order to stay in business. All the business processes must be re-engineered and every tool available must be put to use. Businesses must leverage some of the most advanced technologies like Internet of Thing (IoT), Big Data analytics and Machine Learning to get to top. Only an experienced cloud service provider like LogicSure can help you implement these technologies and let you take advantage of cloud by eliminating the need for investing in a physical IT infrastructure and managing it.",
    }
];
