import styled from 'styled-components';

const Wrapper = styled.section`
    .dropdown-icon {
        font-size: 11px;
        margin-left: 5px;
        vertical-align: bottom;
        svg {
          color: rgb(61,61,61,0.9);
          transition: all 0.3s ease;
        }
    }
    .dropdown-list {
        list-style-type: none;
        margin-left: 40px;
        padding: 5px 0;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.3s ease-out;
    }
    .dropdown-list > .page-link {
        text-decoration: none;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 1px;
        color: rgb(61, 61, 61, 0.9);
        transition: color 0.15s ease-out;
    }
    .dropdown-list:hover {
        border-bottom: 2px solid rgb(0, 102, 255, 0.8);
    }
    .dropdown-list:hover .page-link {
        color: #0066ff;
    }
    .dropdown-list:hover .dropdown-icon {
        svg {
          color: #0066ff;
          transform: rotate3d(1, 0, 0, 180deg);
        }
    }
    .mega-service-menu {
        visibility: hidden;
        position: absolute;
        left: 0;
        // z-index: 99;
        opacity: 0;
        width: 100%;
        transform: translateY(5%);
        transition: all 0.3s ease-in;
        background-color: white;
        box-shadow: 7px 4px 20px rgb(0 0 0 / 7%);
    }
    .dropdown-list:hover+.mega-service-menu,
    .mega-service-menu:hover,
    .dropdown-list:hover+.mega-service-menu .service-menu ul li:first-child .service-menu-right
    {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.3s ease-out;
    }
`;

export default Wrapper;