import React from 'react'
import NavBar from '../components/Navbar'

import ContactForm from '../components/ContactForm';

const ContactUs = () => {
  return (
      <>
        <NavBar/>
        <section className="tell-us-container p-top">
            <h1 className="tell-us-title">Contact Us for <span>our Services</span></h1>
            <div className="tell-us-form-container">
              <ContactForm/>
              <div className="next-steps-detail">
                <h1 className="next-steps-title">What will happen next?</h1>
                <ol className="next-steps">
                  <li>We’ll reach out to you within 24 hours.</li>
                  <li>We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.</li>
                  {/* <li>You can start 15-days risk-free trial with us.</li> */}
                </ol>
              </div>
            </div>
        </section>
      </>
  )
}

export default ContactUs