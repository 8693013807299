import React from "react";
import Wrapper from "../assets/wrappers/Button";

const Button = (props) => {
  return (
    <Wrapper
      type={props.type}
      bgclr={props.bgclr}
      fontSize={props.fontSize}
      fontWeight={props.weight}
      clr={props.clr}
      border={props.border}
    >
      <span>{props.text}</span>
    </Wrapper>
  );
};

export default Button;
