import React from 'react'
import Wrapper from '../assets/wrappers/ServiceDescription';

const ServiceDescription = (props) => {
  return (
    <Wrapper>
        <img src={props.icon} className="service-icon" alt="icon"/>
        <div className="service-details">
            <h1 className="service-title">{props.title}</h1>
            <p className="service-description">{props.desc}</p>
        </div>
    </Wrapper>
  )
}

export default ServiceDescription