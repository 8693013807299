import React from 'react'
// import detailImg from '../assets/images/digital-product-engineering-banner@2x.webp';
import Wrapper from '../assets/wrappers/BannerContainer';
import detailImg from '../assets/images/svg/testing/6QA-engineers-pana.svg'

const QABannerContainer = () => {
  return (
    <Wrapper>
        <h1 className="banner-title">
        Our <span> QA Services</span>
        </h1>
        <h5 className="banner-subtitle">
        Our QA services will help you establish concrete control over your product’s life cycle, monitor every development stage, and give you accurate product quality information. For over a decade, our QA experts have been using the latest practices and technologies to ensure efficient performance and high-quality project implementation for the world’s largest enterprises, aiding in delivering solid software on time.        </h5>
        <img className="banner-img" src={detailImg} alt="detail-img" />
    </Wrapper>
  )
}

export default QABannerContainer