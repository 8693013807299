import React from "react";
import { FaChevronDown } from "react-icons/fa";
import Wrapper from "../assets/wrappers/DropDownList";
import ServiceMenu from "./ServiceMenu";
import { NavLink } from "react-router-dom";

const DropDownList = (props) => {
  return (
    <Wrapper>
      <li className="dropdown-list">
        <NavLink to={{ pathname: props.to }} exact={true} className="page-link">
          {props.title}
        </NavLink>
        <span className="dropdown-icon">
          <FaChevronDown />
        </span>
      </li>
      <div className="mega-service-menu">
        <ServiceMenu details={props.details} />
      </div>
    </Wrapper>
  );
};

export default DropDownList;
