import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 180px 50px 50px;
    margin: 0px 100px;

    .intro-data-container {
        padding-right: 60px;
        flex: 0 1 500px;
        // margin: 20px;
    }
    .home-img {
        height: 230px;
    }
    .title {
        font-size: 40px;
        font-weight: 600;
        margin: 15px 0px;
    }
    .title span {
        background: linear-gradient(100deg, #000000 0%, rgb(0, 102, 255) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .subtitle {
        font-size: 25px;
        font-weight: 400;
        margin-top: 10px;
    }
`;

export default Wrapper;