import styled from "styled-components";

const Wrapper = styled.div`
    padding: 0px 90px;
    margin: 0px 60px;
    background: #fff;
    padding-bottom: 20px;

    .banner-title, .banner-subtitle {
        text-align: center;
    }

    .banner-title {
        font-size: 43px;
        margin: 20px 0px;
    }

    .banner-title span {
        background: linear-gradient(100deg, #000000 0%, rgb(0, 102, 255) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .banner-subtitle {
        font-size: 21px;
        font-weight: 300;
        margin-top: 0px;
    }

    .banner-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 450px;
    }
`;

export default Wrapper;