import icon1 from '../images/digital-product-eng-micon.svg';
import icon2 from '../images/performance-testing-micon.svg';
import icon3 from '../images/app-management-modernization-micon.svg';
import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

export const app_services = [
    {
        id: 1,
        title: "New Software Product Development",
        subtitle: "Turn ideas into powerful digital products",
        icon: icon1,
        to:"/details/appdev",
        description: "Get high quality customized software products within the proposed time and budget. Review of technical and functional practicality of the software product. End-to-end software product development using multiple methodologies and practices including Agile, SCRUM & DevOps",
    },
    {
        id: 2,
        title: "Product Modernization",
        subtitle: "Build products that deliver a consistently great experience. Test early and often.",
        icon: icon2,
        to:"/details/appdev",
        description: "Re-engineer your product with latest technological modules and architecture to remain competitive. Evaluation of the existing product development cycle to improve KPIs, thereby making cost efficient. Version releases controlled at a balanced pace using Agile & DevOps practices",
    },
    {
        id: 3,
        title: "Migration & Integration",
        subtitle: "World-class risk mitigated migration and integration solutions",
        icon: icon3,
        to:"/details/appdev",
        description: "Improve organization efficiency by building automatic process/information flow with other systems and software by seamless integration. Move from a legacy system to a more advanced technology based or platform-based system to enable automation and to scale up with ease.",
    },
    {
        id: 4,
        title: "Quality Analysis & Testing",
        subtitle: "Establish and maintain an efficient QA process throughout the entire software development lifecycle",
        icon: icon1,
        to:"/details/appdev",
        description: "Quality assurance with a balanced composition of manual and automated testing. Expert quality testing ensuring product is secure and scalable at ease. App monitoring and risk management to improve product life expectancy",
    },
    {
        id: 5,
        title: "Maintenance & Support",
        subtitle: "Evoke multi-tier support and maintenance services that can be adjusted flexibly to business needs",
        icon: icon4,
        to:"/details/appdev",
        description: "Dedicated support team fixing bugs/errors and maintaining product stability. Process optimization and efficiency maintenance by upgrading/updating to the latest technologies. Technical support to existing product users while migrating to the latest version",
    }
];
