import React from "react";
import IntroContainer from "../components/IntroContainer";
import Navbar from "../components/Navbar";
import OutsourcingCard from "../components/OutsourcingCard";
import { outsourcing } from "../assets/data/serviceDetails";
import ContactUs from "./ContactUs";

const Services = () => {
  return (
    <>
      <Navbar />
      {/* <IntroContainer /> */}
      <div className="outsourcing-container ">
        <h1>
          Explore <span>Our Services</span>
        </h1>
        <h3 className="outsourcing-subtitle">
          From IT strategy consulting and comprehensive technology roadmaps to
          the end-to-end development of scalable solutions, Intellectsoft
          delivers a full-cycle software development services that adapt
          seamlessly to your project requirements and business needs.
        </h3>
        {outsourcing.map((detail) => (
          <OutsourcingCard
            key={detail.id}
            icon={detail.icon}
            title={detail.title}
            description={detail.description}
            to={detail.to}
          />
        ))}
      </div>
      <div className="perspective-container"></div>
      <ContactUs />
    </>
  );
};

export default Services;
