import icon1 from '../images/digital-product-eng-micon.svg';
import icon2 from '../images/performance-testing-micon.svg';
import icon3 from '../images/app-management-modernization-micon.svg';
import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

export const sf_services = [
    {
        id: 1,
        title: "Salesforce Consulting",
        subtitle: "Experienced and certified salesforce consultants delivering solutions, driving customer and business values.",
        icon: icon1,
        to:"/details/salesforce",
        description: "Our Salesforce consulting services aim to help you maximize your Salesforce ROI to drive sales, improve customer service, build marketing funnel, boost revenue, automate quote-to-cash etc., while cutting down operational costs and time-to-market.",
    },
    {
        id: 2,
        title: "Salesforce Implementation",
        subtitle: "Our unique Salesforce implementation accelerates revenue growth & facilitates sales cycle",
        icon: icon2,
        to:"/details/salesforce",
        description: "Our Salesforce implementation consultants will collaborate with you to assess your existing business processes and technology systems, identify gaps in technical implementation and business usage, discover key system requirements and provide the best ideas for Salesforce optimization. Apex, Visualforce, point & click, and Lightning development to expedite unique and complex needs",
    },
    {
        id: 3,
        title: "Salesforce Integration",
        subtitle: "Seamlessly connect complex systems and automate business processes; achieve cost reduction and efficiently control assets",
        icon: icon3,
        to:"/details/salesforce",
        description: "Integrating Salesforce with internal or external third party SaaS or on-premise apps. Well built Salesforce integrations with third party systems including ERP & accounting systems, CTI solutions, chatbots, payment gateways, e-commerce platforms, marketing automation engines and so on have the potential to transform your Salesforce into a single source of truth for all your business activities. Our team of Salesforce certified consultants will help you build a milestone driven and time sensitive integration roadmap based on your business needs.",
    },
    {
        id: 4,
        title: "Salesforce Support",
        subtitle: "Ongoing Salesforce support services and maintenance help for your Salesforce Org.",
        icon: icon4,
        to:"/details/salesforce",
        description: " Our professionals ensure to deliver top-quality IT support & maintenance services to all our clients. Dedicated Salesforce administrators for point & click development, process automations, user support and more",
    },
];
