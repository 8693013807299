import React from "react";
import Wrapper from "../assets/wrappers/BenefitContainer";
// import img1 from "../assets/images/eminent-expertise@2x.webp";
// import img2 from "../assets/images/intrinsically-agile@2x.webp";
// import img3 from "../assets/images/end-to-end-services@2x.webp";
// import img4 from "../assets/images/scalable-team@2x.webp";
// import img5 from "../assets/images/unmatched-quality@2x.webp";
import waveRight from "../assets/images/process-flow-light-blue.svg";
import waveLeft from "../assets/images/process-flow-light-blue-down.svg";
import { qa_services } from "../assets/data/qaServiceDetails";

import img2 from '../assets/images/svg/testing/2Ok-amico.svg' 
import img1 from '../assets/images/svg/testing/3Computer-troubleshooting-amico.svg' 
import img4 from '../assets/images/svg/testing/7QA-engineers-cuate.svg' 
import img3 from '../assets/images/svg/testing/8QA-engineers-amico.svg' 
import img5 from '../assets/images/svg/testing/9QA-engineers-bro.svg' 

const QABenefitContainer = () => {
  return (
    <Wrapper>
      <div className="benefit-cards">
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img1} alt="img-1" />
        </div>
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{qa_services[0].title}</h3>
          <p>{qa_services[0].description}</p>
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveRight} alt="wave-right" />
      </div>
      <div className="benefit-cards">
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{qa_services[1].title}</h3>
          <p>{qa_services[1].description}</p>
        </div>
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img2} alt="img-2" />
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveLeft} alt="wave-left" />
      </div>
      <div className="benefit-cards">
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img3} alt="img-3" />
        </div>
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{qa_services[2].title}</h3>
          <p>{qa_services[2].description}</p>
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveRight} alt="wave-right" />
      </div>
      <div className="benefit-cards">
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{qa_services[3].title}</h3>
          <p>{qa_services[3].description}</p>
        </div>
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img4} alt="img-4" />
        </div>
      </div>
    </Wrapper>
  );
};

export default QABenefitContainer;
