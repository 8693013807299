import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: rgb(239, 239, 239);
    padding: 15px 0px;

    p {
        margin: 0;
        text-align: center;
        font-size: 15.5px;
        color: rgb(150, 150, 150);
    }
`;

export default Wrapper;