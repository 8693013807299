import icon1 from '../images/digital-product-eng-micon.svg';
import icon2 from '../images/performance-testing-micon.svg';
import icon3 from '../images/app-management-modernization-micon.svg';
import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

export const qa_services = [
    {
        id: 1,
        title: "Manual Testing",
        subtitle: "Checking software or programs for errors, defects, and vulnerabilities by hand from the user's perspective.",
        icon: icon1,
        to:"/details/quality",
        description: "Manual testing is the process of checking software or programs for errors, defects, and vulnerabilities by hand. During manual testing, QA engineers check whether the system operates according to specified requirements without any failures.",
    },
    {
        id: 2,
        title: "Test Automation",
        subtitle: "Services to optimize the testing process without delaying the release, spending budget on building a team, and false failures due to human factors.",
        icon: icon2,
        to:"/details/quality",
        description: "We offers test automation services that help to accurately check the software, prevent human error, speed up testing, increase test coverage, and get a higher return on investment (ROI) in the long run.",
    },
    {
        id: 3,
        title: "Consulting",
        subtitle: "Analyzing testing and development processes, evaluating team readiness for testing, and detecting product drawbacks.",
        icon: icon3,
        to:"/details/quality",
        description: "In order to make sure that the QA strategy satisfies quality standards and meets industry requirements, We provides QA consulting by analyzing testing and development processes, evaluating team readiness for testing and detecting product drawbacks.",
    },
    {
        id: 4,
        title: "Quality Assurance",
        subtitle: "By constantly monitoring and periodically checking different aspects of the software solution, we detect and prevent quality assurance issues where the solution fails to comply with requirements and standards.",
        icon: icon1,
        to:"/details/quality",
        description: "In order to enter the market with a quality software product, every development process includes a set of quality assurance steps to ensure that a team has appropriately defined all processes and everything is ready to reach defined objectives. We performs Software quality assurance (QA) for our customers to provide end users with a product that meets quality standards and fulfills its goals.",
    }
];
