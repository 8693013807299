import React from "react";
import NavBar from "../components/Navbar";
import WebBannerContainer from "../components/WebBannerContainer";
import WebBenefitContainer from "../components/WebBenefitContainer";
import ContactUs from "./ContactUs";

const WebDetails = () => {
  return (
    <>
      <NavBar />
      <section className="detail-container">
        <WebBannerContainer />
      </section>
      <section className="benefit-container">
        <WebBenefitContainer />
      </section>
      <ContactUs />
    </>
  );
};

export default WebDetails;
