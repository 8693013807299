import styled from 'styled-components';

const Wrapper = styled.div `
    margin: 0px 20px;
    padding: 0px 10px;

    .service-menu {
        display: flex;
        height: 455px;
    }
    .service-menu-left-side {
        width: 380px;
        border-right: 1px solid rgb(227, 226, 234);
    }
    .service-menu-left-side ul {
        height: 100%;
    }
    .service-menu-list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 10px 10px 10px 25px;
        border: 1px solid transparent;
        border-radius: 3px;
    }
    .service-menu-list a {
        position: relative;
        width: 100%;
    }
    li {
        list-style-type: none;
    }
    .service-title,
    .service-subtitle {
        margin: 0px;
    }
    .service-subtitle {
        font-size: 0.83rem;
        color: rgb(61, 61, 61);
    }
    a {
        text-decoration: none;
        color: rgb(61, 61, 61, 0.9);
    }
    .service-menu-verticle-line {
        border-right: 1px solid rgb(227, 226, 234);
    }
    ul:hover li:not(:hover) {
        background-color: white;
    }
    ul li:hover , ul li:first-child {
        background-color: rgb(0, 102, 255, 0.1);
    }
    .service-menu-right-arrow {
        font-size: 11px;
        margin-left: 10px;
        svg {
            color: rgb(61,61,61,0.9);
        }
    }
    .service-menu-right {
        position: absolute;
        top: 0%;
        left: 420px;
        height: 100%;
        visibility: hidden;
        // z-index: 99;
        opacity: 0;
        transform: translateY(5%);
        transition: all 0.05s ease-in;
    }
    ul:hover li:not(:hover) .service-menu-right {
        visibility: hidden;
        opacity: 0;
    }

    ul li:hover .service-menu-right
    // .dropdown-list:hover ul li:first-child .service-menu-right 
     {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.2s ease-out;
    }
`

export default Wrapper;

