import React from "react";
import NavBar from "../components/Navbar";
import SFBannerContainer from "../components/SFBannerContainer";
import SFBenefitContainer from "../components/SFBenefitContainer";
import ContactUs from "./ContactUs";

const SFDetails = () => {
  return (
    <>
      <NavBar />
      <section className="detail-container">
        <SFBannerContainer />
      </section>
      <section className="benefit-container">
        <SFBenefitContainer />
      </section>
      <ContactUs />
    </>
  );
};

export default SFDetails;
