import React from 'react'
// import detailImg from '../assets/images/digital-product-engineering-banner@2x.webp';
import detailImg1 from '../assets/images/svg/appdev/Hotel1-Booking-amico.svg' 

import Wrapper from '../assets/wrappers/BannerContainer';

const AppDevBannerContainer = () => {
  return (
    <Wrapper>
        <h1 className="banner-title">
        Our <span> Application Development Services </span>
        </h1>
        <h5 className="banner-subtitle">
        Application development services help create well-designed, user-friendly and fast apps for external or internal users. LogicSure offers experience in UX and UI design, different architecture patterns and tech stacks for app development.        </h5>
        <img className="banner-img" src={detailImg1} alt="detail-img" />
    </Wrapper>
  )
}

export default AppDevBannerContainer