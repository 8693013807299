import styled from "styled-components";

const Wrapper = styled.div`
    padding: 0px 100px;
    margin: 0px 60px;

    .benefit-title {
        text-align: center;
        font-size: 38px;
        padding: 0px auto;
        margin: 0px 250px;
    }

    .benefit-title span {
        text-decoration: underline rgb(77, 148, 255) solid 5px;
    }

    .benefit-desc {
        padding: 20px;
        font-size: 20px;
        text-align: center;
        margin: 20px 50px;
    }

    .benefit-cards {
        display: flex;
        align-items: center;
        margin: 30px 10px;
    }

    .three-layer-block {
        width: 100%;
        height: 418px;
        margin: 0px 50px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .first-layer {
    //     padding: 19px;
    //     background: linear-gradient(to top, rgb(255, 255, 255), rgb(238, 242, 255));
    //     opacity: 1;
    //     width: calc(100% - 60px);
    //     height: calc(100% - 60px);
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    // }

    .benefit-card-img {
        width: 300px;
        height: 300px;
        padding: 20px;
        box-shadow: rgb(161 175 221 / 9%) 0px 5px 10px 0px;
        border: 1px solid rgb(232, 233, 243);
    }

    .benefit-card-title {
        font-size: 25px;
    }

    .benefit-card-detail p {
        font-size: 22px;
    }

    .benefit-card-detail {
        flex: 0 1 auto;
        padding: 30px;
    }

    .path-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -50px 0px;
    }
`;

export default Wrapper;