import React from "react";
import Wrapper from "../assets/wrappers/Navbar";
import DropDownList from "./DropDownList";
import { cloud_services } from "../assets/data/cloudServiceDetails";
import { app_services } from "../assets/data/appServiceDetails";
import { analytics_services } from "../assets/data/analyticsServiceDetails";
import { sf_services } from "../assets/data/sfServiceDetails";
import { web_services } from "../assets/data/webServiceDetails";
import { qa_services } from "../assets/data/qaServiceDetails";
import { NavLink } from "react-router-dom";

import Button from "./Button";

const NavBar = () => {
  function scrollToTop() {
    return window.scrollTo(0, 0);
  }

  return (
    <Wrapper>
      <header className="navbar-top">
        <div className="nav-wrapper">
          <NavLink
            to="/"
            onClick={scrollToTop}
            exact={true}
            className="left brand-logo"
          >
            <div className="logo-decoration">
              <h1>
                <span>LogicSure</span>
              </h1>
            </div>
          </NavLink>
        </div>
        <div className="navbar-top-desktop">
          <ul className="navbar-links">
            <li>
              <NavLink to="/" onClick={scrollToTop} exact={true}>
                About Us
              </NavLink>
            </li>

            <li>
              <NavLink to="/services" onClick={scrollToTop}>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/contactus" onClick={scrollToTop}>
                <Button
                  type="button"
                  text="Contact Us"
                  bgclr={"rgb(0, 102, 255)"}
                />
              </NavLink>
            </li>
          </ul>
        </div>
      </header>
      <header className="navbar-bottom">
        <ul className="navbar-links">

          <NavLink
            to="/details/appdev"
            onClick={scrollToTop}
            className="page-link"
            exact={true}
          >
            <DropDownList
              title="Application Development"
              details={app_services}
              to="/details/appdev"
            />
          </NavLink>

          <NavLink
            to="/details/analytics"
            onClick={scrollToTop}
            className="page-link"
            exact={true}
          >
            <DropDownList
            title="BI Analytics"
            details={analytics_services}
            to="/details/analytics"
            />
          </NavLink>

          <NavLink
            to="/details/salesforce"
            onClick={scrollToTop}
            className="page-link"
            exact={true}
          >
            <DropDownList
            title="Salesforce"
            details={sf_services}
            to="/details/salesforce"
            />
          </NavLink>

          {/* <NavLink
            to="/details/web"
            onClick={scrollToTop}
            className="page-link"
          >
            <DropDownList title="Web Technologies" details={web_services} />
          </NavLink> */}

          <NavLink
            to="/details/cloud"
            onClick={scrollToTop}
            className="page-link"
            exact={true}
          >
            <DropDownList
            title="Cloud Technologies"
            details={cloud_services}
            to="/details/cloud"
            />
          </NavLink>

          <NavLink
            to="/details/quality"
            onClick={scrollToTop}
            className="page-link"
            exact={true}
          >
            <DropDownList
            title="QA Services"
            details={qa_services}
            to="/details/quality"
            />
          </NavLink>
        </ul>
      </header>
    </Wrapper>
  );
};

export default NavBar;
