import React, {useEffect} from "react";
import Wrapper from "../assets/wrappers/OutsourcingCard";
import arrowImg from "../assets/images/card-next-arrow.svg";
import {Link} from "react-router-dom"
import { NavLink } from "react-router-dom";

const OutsourcingCard = (props) => {

  function scrollToTop() {
    return window.scrollTo(0, 0);
  }

  useEffect(()=> {
    scrollToTop()  },[])

  return (
    <Wrapper>
     
      <div className="title-icon-container">
      <Link
            to={props.to}
            onClick={scrollToTop}
            exact={true}
            className="text-decoration__remove"
          >
        <img src={props.icon} alt={props.title} />
        </Link>

        <Link
            to={props.to}
            onClick={scrollToTop}
            exact={true}
            className="text-decoration__remove"
          >
        <h2>{props.title}</h2>
        </Link>

      </div>
      <div className="outsourcing-desc-container">
      <Link
            to={props.to}
            onClick={scrollToTop}
            exact={true}
            className="text-decoration__remove"
          >
        <p className="outsourcing-desc">{props.description}</p>
                </Link>

        <div className="link-img">
        <Link  to={{pathname: props.to}} >
          <img className="arrow" src={arrowImg} alt="arrow" />
        </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default OutsourcingCard;
