import React from 'react'
// import detailImg from '../assets/images/digital-product-engineering-banner@2x.webp';
import Wrapper from '../assets/wrappers/BannerContainer';
import detailImg from '../assets/images/svg/cloud/Image-upload-bro.svg' 

const BannerContainer = () => {
  return (
    <Wrapper>
        <h1 className="banner-title">
        Our cloud <span> consulting services</span>
        </h1>
        <h5 className="banner-subtitle">
        Our team will collaborate with you to assess your current IT setup to define an ideal approach to migrate your business applications into cloud, and execute it successfully regardless of your application’s complexity. LogicSure is also efficient in delivering scalable, secured and high performing custom cloud applications when the situation calls for it.
        </h5>
        <img className="banner-img" src={detailImg} alt="detail-img" />
    </Wrapper>
  )
}

export default BannerContainer