import React from 'react'
import Wrapper from '../assets/wrappers/Location'
import AddressCard from './AddressCard';

const Location = () => {
  return (
    <Wrapper>
        <div className="address-container">
        <h3>Areas We Serve</h3>
        <div className="address-cards">
            <AddressCard country="India" address="No.18, Flat A2, Vinayagapuram 3rd Street, Arumbakkam, Chennai, India"/>
            {/* <AddressCard country="Canada" address="15-115 - Bentall II Vancouver BC, V7X 1M8"/>
            <AddressCard country="India" address="501 Binori B Sq-2, Nr DoubleTree By Hilton, Bopal Rd, Ahmedabad-380054, India"/>
            <AddressCard country="Austin" address="701 Tillery St #12, Austin, TX 78702 USA"/> */}
        </div>
          </div>
    </Wrapper>
  )
}

export default Location