import styled from 'styled-components';

const Wrapper = styled.div `
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding: 30px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 6%) 0px 0px 10px;
    margin-bottom: 20px;
    cursor: pointer;

    .title-icon-container {
        display: flex;
        border-right: 1px solid rgb(220, 218, 218);
        margin-right: 20px;
        flex: 0 0 266px;
    }

    .title-icon-container img {
        height: 50px;
        align-self: center;
        margin-right: 20px;
    }

    .link-img {
        align-self: center;
    }

    .title-icon-container h2 {
        padding-right: 20px;
    }

    .outsourcing-desc-container {
        display: flex;
    }

    .outsourcing-desc {
        margin: 0px 25px 0px 0px;
        font-size: 19px;
        font-weight: 300;
    }

    .arrow {
        transform: translateX(0px);
        transition: all 0.4s ease-in-out 0ms;
        filter: sepia(114%) hue-rotate(190deg) saturate(9000%);
    }

    &:hover {
        box-shadow: 0px 0px 15px 8px rgb(0 0 0 / 6%);
    }

    &:hover .arrow {
        transform: translateX(30%);
        transition: all 0.4s ease-in-out 0s;
    }
`;

export default Wrapper;