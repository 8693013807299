import React from 'react'
// import detailImg from '../assets/images/digital-product-engineering-banner@2x.webp';
import Wrapper from '../assets/wrappers/BannerContainer';
import detailImg from '../assets/images/svg/bi/Data9-report-pana.svg' 

const AnalyticsBannerContainer = () => {
  return (
    <Wrapper>
        <h1 className="banner-title">
        Our <span>Business Intelligence And Data Analytics Services</span>
        </h1>
        <h5 className="banner-subtitle">
        We help you extract meaningful insights from any data source and drive business with informed decision-making capabilities.        </h5>
        <img className="banner-img" src={detailImg} alt="detail-img" />
    </Wrapper>
  )
}

export default AnalyticsBannerContainer