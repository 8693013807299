import React from 'react'
// import detailImg from '../assets/images/digital-product-engineering-banner@2x.webp';
import Wrapper from '../assets/wrappers/BannerContainer';
import detailImg from '../assets/images/svg/sf/6Online-world-amico.svg' 

const SFBannerContainer = () => {
  return (
    <Wrapper>
        <h1 className="banner-title">
        Our <span> Salesforce Consulting Services</span>
        </h1>
        <h5 className="banner-subtitle">
        We understand the true power of the Salesforce platform, and we always strive to address your needs using out-of-the-box features of Salesforce to the maximum extent. We will resort to custom development only in cases where it is absolutely necessary.        </h5>
        <img className="banner-img" src={detailImg} alt="detail-img" />
    </Wrapper>
  )
}

export default SFBannerContainer