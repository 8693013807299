import React from 'react'
import Wrapper from '../assets/wrappers/AddressCard'

const AddressCard = (props) => {
  return (
    <Wrapper>
        <h3>{props.country}</h3>
        <p>{props.address}</p>
        <p>{"Email: info@logic-sure.com"}</p>
        {/* <a href="/#">Call Us Now</a> */}
    </Wrapper>
  )
}

export default AddressCard