import React from "react";
import NavBar from "../components/Navbar";
import QABannerContainer from "../components/QABannerContainer";
import QABenefitContainer from "../components/QABenefitContainer";
import ContactUs from "./ContactUs";

const QADetails = () => {
  return (
    <>
      <NavBar />
      <section className="detail-container">
        <QABannerContainer />
      </section>
      <section className="benefit-container">
        <QABenefitContainer />
      </section>
      <ContactUs />
    </>
  );
};

export default QADetails;
