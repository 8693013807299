import styled from "styled-components";

const Wrapper = styled.div`
    padding-right: 20px;
    margin-right: 20px;

    a {
        text-decoration: none;
        color: inherit;
        font-weight: bold;
        cursor: pointer;
    }

    a:hover {
        color: rgb(0, 102, 255);
        transition: color 0.15s ease-out;
    }
`;

export default Wrapper;