import styled from 'styled-components';
import map from '../images/worldmap.jpg';

const Wrapper = styled.div`
    padding: 20px 70px;
    background: url(${map}) no-repeat 100%;
    object-fit: fill ;
    background-size:100% auto;

    .address-container {
        margin-top: 100px;
        padding: 30px;
    }

    .address-cards {
        display: flex;
    }
`;

export default Wrapper;