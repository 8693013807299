import React from 'react'
import NavBar from '../components/Navbar'
import CloudBannerContainer from '../components/CloudBannerContainer';
import CloudBenefitContainer from '../components/CloudBenefitContainer';
import ContactUs from "./ContactUs";

const CloudDetails = () => {
  return (
      <>
        <NavBar/>
        <section className="detail-container">
          <CloudBannerContainer/>
        </section>
        <section className="benefit-container">
          <CloudBenefitContainer/>
        </section>
        <ContactUs/>
      </>
  )
}

export default CloudDetails