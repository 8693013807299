import React from 'react'
import Wrapper from '../assets/wrappers/CopyRight'

const Footer = () => {
  return (
      <Wrapper>
        <p>Copyright © 2023 LogicSure. All Rights Reserved.</p>
      </Wrapper>
  )
}

export default Footer