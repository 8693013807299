import icon1 from '../images/digital-product-eng-micon.svg';
import icon2 from '../images/performance-testing-micon.svg';
import icon3 from '../images/app-management-modernization-micon.svg';
import icon4 from '../images/s-digital-exp-design-icon.svg';
import bookImg1 from '../images/ebook-building-battle-tested-software-architecture@2x.webp';
import bookImg2 from '../images/ebook-enterprise_kubernative_strategy@2x.webp';
import bookImg3 from '../images/ebook-building-scalable-frontend@2x.webp';
import bookImg4 from '../images/ebook-microservices-are-transforming@2x.webp';

export const analytics_services = [
    {
        id: 1,
        title: "Consulting",
        subtitle: "Key lift your organization's transformation to a data-driven culture.",
        icon: icon1,
        to:"/details/analytics",
        description: "Carry out a thorough assessment to understand your business process to set up, as well as optimize BI roadmap & strategy for you by studying your internal and external systems.",
    },
    // {
    //     id: 2,
    //     title: "ETL - Extract, Transform And Load Process",
    //     subtitle: "Build products that deliver a consistently great experience. Test early and often.",
    //     icon: icon2,
    //     description: "Extract data from various databases, transform and load them in to desired systems such as data warehousing or analytical applications",
    // },
    {
        id: 3,
        title: "Data Warehousing",
        subtitle: "Store data from multiple sources in a centralized repository for better business intelligence, accurate analytics, and informed decision-making",
        icon: icon3,
        to:"/details/analytics",
        description: "Perform data analysis, profiling and system study to architect dimensionally modeled data warehouse. Our services includes Data Modeling, Data Integration, Data Mart, Data Analysis and Design, Maintenance, Operation & Support of the Data Warehouse",
    },
    {
        id: 4,
        title: "Data Integration",
        subtitle: "Build process that deliver a consistently great experience",
        icon: icon1,
        to:"/details/analytics",
        description: "Leverage the power of raw data by merging data from various sources in unrelated formats to form a meaning full information. The provision of an integration or API solution as a complete end-to-end service",
    },
    // {
    //     id: 5,
    //     title: "Data Mining",
    //     subtitle: "Turn ideas into powerful digital products",
    //     icon: icon4,
    //     to:"/details/analytics",
    //     description: "Identify patterns, relationships and trends in your business data that can help you make informed decisions. Our services includes website, social media, SQL, Excel, open sources etc.",
    // },
    {
        id: 6,
        title: "Data Visualization",
        subtitle: "Reveal critical data to business users via intuitive visuals",
        icon: icon4,
        to:"/details/analytics",
        description: "Analyze and present data in actionable information enabling executives, managers, and other end users to take the right & well informed decisions. Advanced visualization tools like Power BI, Tableau, Qlik etc. are used to present the information extracted from data in a more appealing form like standard or personalized dashboards",
    },
    {
        id: 7,
        title: "Business Intelligence Solution",
        subtitle: "Automate insights extraction from input data",
        icon: icon4,
        to:"/details/analytics",
        description: "Business Intelligence Solutions on cloud with application such as Power BI, Tableau, MicroStrategy, Birst and Talend",
    },
    // {
    //     id: 8,
    //     title: "Big Data        ",
    //     subtitle: "Turn ideas into powerful digital products",
    //     icon: icon4,
    //     description: "Conceptualize, strategize, and implement a well-planned big data solution to provide decisive analytics & reports that helps make critical business decisions.",
    // },
    // {
    //     id: 9,
    //     title: "Report Development",
    //     subtitle: "Turn ideas into powerful digital products",
    //     icon: icon4,
    //     description: "Analyze and present data in actionable information enabling executives, managers, and other end users to take the right & well informed decisions.",
    // },
];
