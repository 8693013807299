import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  padding: 70px 120px;
  background: white;

  li {
    list-style-type: none;
    line-height: 30px;
    cursor: pointer;
    text-decoration: solid;
  }

  ul {
    padding: 0px;
    margin: 10px 0px;
  }

  h3 {
    margin-top: 0;
  }

  .info-links-section,
  .about-section,
  .service-section,
  .resources-section {
    margin: 0px 30px;
    width: -webkit-fill-available;
  }

  .company-logo {
    width: 140px;
    cursor: pointer;
    /* filter: sepia(114%) hue-rotate(190deg) saturate(9000%); */
  }

  .social-media {
    display: flex;
    align-items: center;
    margin: 25px 0px;
    svg {
      margin-right: 10px;
      font-size: 20px;
      color: rgb(150, 150, 150);
    }
    cursor: pointer;
  }

  .info-links-firm {
    margin: 5px 0px;
    background: rgb(244, 244, 244);
    border: 0.8px solid rgb(220, 218, 218);
    border-radius: 0.3px;
    cursor: pointer;
  }

  .info-links-firm img {
    margin: 10px;
    width: 80px;
  }

  .info-links-firm img:first-child {
    width: 50px;
    padding-right: 12px;
    border-right: 1px solid rgb(220, 218, 218);
  }

  .dmceBadge {
    margin: 10px 0px;
    cursor: pointer;
  }

  hr {
    border-top: 1px solid rgb(220, 218, 218);
  }

  li:hover {
    color: rgb(0, 102, 255);
    transition: color 0.15s ease-out;
  }

  .social-media svg:hover {
    color: rgb(0, 102, 255);
    transition: color 0.15s ease-out;
  }
  .txt-decoration-remove {
    text-decoration: "none";
  }

  .txt-logo-decoration {
    /* text-decoration: underline rgb(77, 148, 255) solid 3px; */
    background: linear-gradient(
      100deg,
      rgb(77, 148, 255) 0%,
      rgb(0, 102, 255) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateY(18px);
    transition: all 0.9s;
    /* animation: .90s shake infinite alternate; */
    font-size: larger;
  }
`;

export default Wrapper;