import React from "react";
import NavBar from "../components/Navbar";
import AnalyticsBannerContainer from "../components/AnalyticsBannerContainer";
import AnalyticsBenefitContainer from "../components/AnalyticsBenefitContainer";
import ContactUs from "./ContactUs";

const AnalyticsDetails = () => {
  return (
    <>
      <NavBar />
      <section className="detail-container">
        <AnalyticsBannerContainer />
      </section>
      <section className="benefit-container">
        <AnalyticsBenefitContainer />
      </section>
      <ContactUs />
    </>
  );
};

export default AnalyticsDetails;
