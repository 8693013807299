import React from 'react'
import detailImg from '../assets/images/digital-product-engineering-banner@2x.webp';
import Wrapper from '../assets/wrappers/BannerContainer';

const WebBannerContainer = () => {
  return (
    <Wrapper>
        <h1 className="banner-title">
        Our <span> Web Solutions services</span>
        </h1>
        <h5 className="banner-subtitle">
        Web development services help create all types of web-based software and ensure great experience for web users. Different types of web solutions may seem similar from the outside but we approach them differently and know what factors are winning in each case.        </h5>
        <img className="banner-img" src={detailImg} alt="detail-img" />
    </Wrapper>
  )
}

export default WebBannerContainer