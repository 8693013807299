import { BrowserRouter, Routes, Route } from "react-router-dom";
import CopyRight from "./components/CopyRight";
import InfoLinks from "./components/InfoLinks";
import Location from "./components/Location";
import CloudDetails from "./pages/CloudDetails";
import Home from "./pages/Home";
import AnalyticsDetails from "./pages/AnalyticsDetails";
import AppDevDetails from "./pages/AppDevDetails";
import SFDetails from "./pages/SFDetails";
import WebDetails from "./pages/WebDetails";
import QADetails from "./pages/QADetails";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/details/cloud" element={<CloudDetails />} />
          <Route path="/details/appdev" element={<AppDevDetails />} />
          <Route path="/details/analytics" element={<AnalyticsDetails />} />
          <Route path="/details/salesforce" element={<SFDetails />} />
          <Route path="/details/web" element={<WebDetails />} />
          <Route path="/details/quality" element={<QADetails />} />
        </Routes>
      </main>
      <footer>
        <Location />
        <InfoLinks />
        <CopyRight />
      </footer>
    </BrowserRouter>
  );
}

export default App;
