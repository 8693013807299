import React, { useState } from 'react'
import Wrapper from '../assets/wrappers/ContactForm';
import Button from './Button';
import { toast } from 'react-toastify';

const Form = () => {
  const initialState = {
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  }

  const [formValues, setFormValues] = useState(initialState);

    const handleChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setFormValues({...formValues, [name]: value});
    }

    const onSubmit = (e) => {
      e.preventDefault();
      console.log("form value after submit", formValues)
      const { name, email, message, phoneNumber } = formValues;
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Username: "vswnth3@gmail.com",
        Password: "390867E0E026BB1988A96FB7443201BDBB06",
        To: "info@logic-sure.com",
        From: "vswnth3@gmail.com",
        Subject: "Name: " + name + ' and ' +'Email: '+ email + ' and ' + 'Phone no: ' + phoneNumber,
        Body: message,
      }).then((emailmsg) => {toast.info(emailmsg)
        setFormValues(initialState)});
    }

  return (
    <Wrapper>
        <h4 className="form-title">Provide your details with your Queries</h4>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-row">
            <input id='name' type='text' value={formValues.name} name='name' onChange={handleChange} className="form-input" required/>
            <label htmlFor="name">Name</label>
          </div>
          <div className="form-row">
            <input id='email' type='email' value={formValues.email} name='email' onChange={handleChange} className="form-input" required/>
            <label htmlFor="email">Email</label>
          </div>
          <div className="form-row">
            <input id='phoneNumber' type='number' value={formValues.phoneNumber} name='phoneNumber' onChange={handleChange} className="form-input" onWheel={(e) => e.target.blur()} required/>
            <label htmlFor="phoneNumber">Phone Number</label>
          </div>
          <div className="form-row">
            <textarea id='message' value={formValues.message} name='message' onChange={handleChange} className="form-textarea" required/>
            <label htmlFor="message">Message</label>
          </div>
          <Button type="submit" text="Submit Details" bgclr={'rgb(0, 102, 255)'} fontSize='17px'/>
        </form>
    </Wrapper>
  )
}

export default Form