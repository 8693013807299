import React from 'react'
import { FaChevronRight } from 'react-icons/fa';
import Wrapper from '../assets/wrappers/ServiceMenu';
import ServiceDescription from './ServiceDescription';

const ServiceMenu = (props) => {
  return (
    <Wrapper>
        <div className="service-menu">
            <div className="service-menu-left-side">
            <ul>
                {props.details.map((detail) => {
                    return (
                        <li key={detail.id} className="service-menu-list">
                                <a href="/#">
                                    <h5 className="service-title">{detail.title}</h5>
                                    <p className="service-subtitle">{detail.subtitle}</p>
                                </a>
                                <span className="service-menu-right-arrow"><FaChevronRight /></span>
                            <div className="service-menu-right">
                                <ServiceDescription title={detail.title} desc={detail.description} icon={detail.icon} />
                            </div>
                        </li>
                    );
                })}
            </ul>
            </div>
        </div>
    </Wrapper>
  )
}

export default ServiceMenu