import React from "react";
import Wrapper from "../assets/wrappers/InfoLinks";
import titleImg from "../assets/images/ls.png";
import footerClutch from "../assets/images/foot-clutch-logo.svg";
import footerFirm from "../assets/images/foot-foodfirms-logo.svg";
import dmceBadge from "../assets/images/dmca-badge-w100-5x1-04.png";
import {
  FaFacebookF,
  FaLinkedin,
  FaMedium,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const InfoLinks = () => {
  function scrollToTop() {
    return window.scrollTo(0, 0);
  }

  return (
    <Wrapper>
      <div className="info-links-section">
        <div className="nav-wrapper">
          <NavLink
            to="/"
            onClick={scrollToTop}
            exact={true}
            className="txt-decoration-remove"
          >
            <div className="txt-logo-decoration txt-decoration-remove">
              <h1>
                <span>LogicSure</span>
              </h1>
            </div>
          </NavLink>
        </div>
        <div className="social-media">
          {/* <FaFacebookF/>
                <FaLinkedin/>
                <FaTwitter/>
                <FaYoutube/>
                <FaMedium/> */}
        </div>
        <div className="info-links-firm">
          {/* <img src={footerClutch} alt="footerClutch"/>
                <img src={footerFirm} alt="footerFirm"/> */}
        </div>
        {/* <img src={dmceBadge} alt="dmceBadge" className="dmceBadge"/> */}
      </div>
      <div className="about-section">
        <h3>About LogicSure</h3>
        <hr />
        <ul>
          <li>
            <NavLink
              to="/"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contactus"
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Contact us
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="service-section">
        <h3>Services</h3>
        <hr />
        <ul>
          
          <li>
            <NavLink
              to="/details/appdev"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Application Development
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/details/analytics"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Analytics
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/details/salesforce"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Salesforce
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/details/web"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Web Technologies
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/details/cloud"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              Cloud Technologies
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/details/quality"
              exact={true}
              onClick={scrollToTop}
              style={{ textDecoration: "none" }}
            >
              QA Services
            </NavLink>
          </li>
        </ul>
      </div>
      {/* <div className="resources-section">
              <h3>Resources</h3>
              <hr/>
              <ul>
                  <li>Blog</li>
                  <li>Ebooks</li>
                  <li>Case Studies</li>
              </ul>
          </div> */}
    </Wrapper>
  );
};

export default InfoLinks;
