import React from "react";
import Wrapper from "../assets/wrappers/BenefitContainer";
// import img1 from "../assets/images/eminent-expertise@2x.webp";
// import img2 from "../assets/images/intrinsically-agile@2x.webp";
// import img3 from "../assets/images/end-to-end-services@2x.webp";
// import img4 from "../assets/images/scalable-team@2x.webp";
// import img5 from "../assets/images/unmatched-quality@2x.webp";
import waveRight from "../assets/images/process-flow-light-blue.svg";
import waveLeft from "../assets/images/process-flow-light-blue-down.svg";
import { analytics_services } from "../assets/data/analyticsServiceDetails";
import img1 from '../assets/images/svg/bi/Business1-analytics-bro.svg' 
import img2 from '../assets/images/svg/bi/Business2- analytics-rafiki.svg' 
import img3 from '../assets/images/svg/bi/Business3-analytics-amico.svg' 
import img4 from '../assets/images/svg/bi/Business4-analytics-pana.svg' 
import img5 from '../assets/images/svg/bi/Data6-report-amico.svg' 
const AnalyticsBenefitContainer = () => {
  return (
    <Wrapper>
      <div className="benefit-cards">
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img1} alt="img-1" />
        </div>
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{analytics_services[0].title}</h3>
          <p>{analytics_services[0].description}</p>
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveRight} alt="wave-right" />
      </div>
      <div className="benefit-cards">
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{analytics_services[1].title}</h3>
          <p>{analytics_services[1].description}</p>
        </div>
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img2} alt="img-2" />
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveLeft} alt="wave-left" />
      </div>
      <div className="benefit-cards">
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img3} alt="img-3" />
        </div>
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{analytics_services[2].title}</h3>
          <p>{analytics_services[2].description}</p>
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveRight} alt="wave-right" />
      </div>
      <div className="benefit-cards">
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{analytics_services[3].title}</h3>
          <p>{analytics_services[3].description}</p>
        </div>
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img4} alt="img-4" />
        </div>
      </div>
      <div className="path-img-container">
        <img src={waveLeft} alt="wave-left" />
      </div>
      <div className="benefit-cards">
        <div className="three-layer-block">
          <img className="benefit-card-img" src={img5} alt="img-5" />
        </div>
        <div className="benefit-card-detail">
          <h3 className="benefit-card-title">{analytics_services[4].title}</h3>
          <p>{analytics_services[4].description}</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default AnalyticsBenefitContainer;
