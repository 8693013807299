import styled from "styled-components";

const Wrapper = styled.button`
    // .navbar-top-contactus {
        font-family: inherit;
        position: relative;
        font-weight: ${(props) => props.fontWeight} 600 ;
        letter-spacing: 1px;
        font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
        color: ${(props) => props.clr ? props.clr : '#fff'};
        background-color: ${(props) => props.bgclr};
        border: 1px solid ${(props) => props.border ? props.border : props.bgclr};
        padding: 5px 5px;
        border-radius: 5px;
        cursor: pointer;
        overflow: hidden;
        text-transform: capitalize;
        transition: color 0.2s ease-in-out 0s, background-color 0.1s ease 0.02s;
    // }
    span {
      position: relative;
      z-index: 1;
    }
    &:hover {
        color: ${(props) => props.bgclr};
        transition-delay: 0s, 0.15s;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.clr ? props.clr : '#fff'};;
      transform: translateY(-100%);
        transition: transform 15ms ease-in-out 0s;
    }
    &:hover::before {
      transform: translateY(0px);
      transition: transform 0.2s ease-in-out 0s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.clr ? props.clr : '#fff'};;
      transform: translateY(100%);
      transition: transform 0.2s ease-in-out 0s;
    }
    &:hover::after {
      transform: translateY(0px);
      transition: transform 0ms ease-in-out 0.2s;
    }
`;

export default Wrapper;